import { guard } from 'effector'
import {
  Config,
  $isTrackingForbidden,
  start,
  botFilter,
  createLegacyTransport,
  resolveConfig,
  createUserIdProvider,
  trackerInit,
  dispatchCustomEvent,
  baseDomEventsCollection,
} from 'rete-analytics-framework'

const baseURL = "https://ftrack-1.server.retentioneering.com/endpoint"

const defaultConfig: Config = {
  userIdProvider: createUserIdProvider({
    baseURL,
    path: "/id",
  }),
  thirdPartyCookieStorage: {
    baseURL,
    getStorageURL: "/storage/get",
    setStorageURL: "/storage/update",
  },
  domObservers: {
    configs: [],
  },
  domEvents: {
    config: {
      collections: [
        {
          name: "main_events_collections",
          events: [],
        },
        baseDomEventsCollection,
      ],
    },
  },
  endpoints: [
    {
      name: 'default',
      guard: {
        fraction: {
          all: 1
        },
        filters: [
          botFilter,
        ],
      },
      transport: createLegacyTransport({
        source: 'rete_tools_docs',
        baseURL,
        path: '/event',
        version: '1.0.0',
        sendCustomEventsData: "event_value",
      }),
    },
  ],
}

const config = resolveConfig({
  defaultConfig,
  configMap: [],
})

start(config)

const win = window as any
win.reteAnalyticsHealthCheck = true

guard({
  clock: trackerInit,
  filter: $isTrackingForbidden,
  target: dispatchCustomEvent.prepend(() => ({
    type: "custom-event",
    name: "tracking-forbidden",
    data: "",
    endpointsOptions: [{
      name: "default",
      force: true,
    }]
  }))
})
